import { Navigate, Route, Routes } from "react-router-dom";
import Navbar from "./Components/Navbar";
import Home from "./Components/Home";
import Footer from "./Components/Footer";
import Four0Four from "./Components/Four0Four";
import { useState } from "react";
import PrivateRoute from "./PrivateRoute";
import AuthVerify from "./Components/authVerify";

function App() {
  // this state used for user authentication and getting user data  
  const [isAuthenticatedNormal, setIsAuthenticated] = useState(false);
  const [authDetails ,setAuthDetails] = useState(null);
  // end here 
  return (  
      <Routes>
        <Route path="/" element={isAuthenticatedNormal ? <Navigate to="/home" /> : <Navigate to="/authVerify" />} />
        <Route path="*" element={<Four0Four></Four0Four>} />
        <Route path="/authVerify" element={<AuthVerify setAuthDetails={setAuthDetails} setIsAuthenticated={setIsAuthenticated}></AuthVerify>}/>
        <Route path="/home" element={<PrivateRoute component={<><Navbar setIsAuthenticated={setIsAuthenticated}/><Home userData={authDetails} isAuth={isAuthenticatedNormal} /><Footer /></>} isAuthenticated={isAuthenticatedNormal} />} />
      </Routes>
  );
}
export default App;
