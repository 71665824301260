import React from "react";
const CommonComp = (props) => {
  return (
    <section className={props.bgColor} id={props.id}>
      <div className="container mx-auto pt-16 md:pt-20">
        <h2 className={`text-2xl ${props.textColor} mb-6 lg:mb-10`}>
          {props.title}
        </h2>
        {props.children} 
      </div>
    </section>
  );
};

export default CommonComp;
