import React from 'react'
import {FaFacebookF, FaLinkedinIn} from 'react-icons/fa'
import { NavHashLink } from 'react-router-hash-link';
import { Link } from 'react-router-dom';
// this area is for footer links 
const navlinks = [
    { title: "Real Time Usage", link: "#realTimeUsage" },
    { title: "Energy Dashboard", link: "#historical" },
    { title: "Weather and Solar Forecast", link: "#forecast" },
    { title: "Energy Tariffs and Schedules", link: "#energy" },
    { title: "Your Details", link: "#user" },
  ];
// end here 
const Footer = () => {
    return (
        <footer className='mt-20'>
            <div className='bg-[#0d4883] pt-6'>
                <div className="container mx-auto">
                    <h2 className="text-center text-xl mb-6 uppercase text-white opacity-80">Follow us on</h2>
                    <div className='flex gap-4 justify-center items-center mb-8'>
                        <Link target="_blank" to="https://www.facebook.com/people/Maximisemyenergy/100089716101234/" className='bg-[#cdd8e4] w-10 h-10 flex items-center justify-center rounded-full'>
                            <FaFacebookF className='text-[#0d4883] text-xl'/>
                        </Link>
                        <Link target="_blank" to="https://www.linkedin.com/company/maximisemy-energy/" className='bg-[#cdd8e4] w-10 h-10 flex items-center justify-center rounded-full'>
                            <FaLinkedinIn className='text-[#0d4883] text-xl'/>
                        </Link>
                    </div>
                    <div className='flex flex-wrap justify-center items-center gap-2 md:gap-10 mb-8'>
                        {navlinks.map((item, index) => {
                            return(
                                <NavHashLink to={item.link} smooth key={index} className="text-[#cdd8e4]">{item.title}</NavHashLink>
                            )
                        })}
                    </div>
                    <div className='md:rounded-t-full rounded-t-lg bg-brand-blue pt-4 pb-2  px-8 flex flex-wrap items-center justify-center gap-4'>
                        <p className='text-white text-sm'>Copyright © 2023 Maximisemyenergy.com All rights reserved.</p>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default Footer