import React, { useEffect, useState } from "react";
import CommonComp from "./CommonComp";
import { Link } from "react-router-dom";
const User = ({ authId }) => {
  let auth0Id = localStorage.getItem('userId')
  const [userData, setUserData] = useState(null);
  const [octopus, setOctopus] = useState(null);
  const apiName = process.env.NODE_ENV === "production" ? process.env.REACT_APP_PRODUCTION_API_URL : process.env.REACT_APP_DEV_API_URL;
  useEffect(() => {
    async function MainHeaderApi() {
      try {
        const response = await fetch(
          `${apiName}/user.php?user_id=${auth0Id}`, {
          method: "GET",
          redirect: "follow",
        }
        );
        if (!response.ok) {
          throw new Error("something went wrong");
        }
        const data = await response.json();
        setUserData(data.customer_data);
        setOctopus(data.customer_data.energyProvider);
      } catch (error) {
        console.log("something wrong");
      }
    }
    MainHeaderApi();
  }, [auth0Id, apiName]);
  return (
    <CommonComp
      title="Your Details"
      textColor="text-brand-blue"
      id="user"
    >
      <div style={{ boxShadow: "0px 0px 34px 0px rgba(0, 0, 0, 0.03)" }} className="border border-gray-200 lg:p-8 p-4 rounded-xl">
        <div className="flex items-center mb-4 justify-between">
          <h2 className="text-gray-800 text-xl ">Location</h2>
          <Link to="https://www.maximisemy.energy/my-account/" target="_blank" className="bg-brand-green cursor-pointer px-4 py-2 text-white rounded-md">Edit</Link>
        </div>
        <div className="flex gap-x-10 gap-y-6 flex-wrap mb-8">
          <div className="basis-full md:basis-[30%] grow">
            <div className="flex item-center justify-between">
              <p className="text-gray-700">Premises Lat/Lon:</p> <p className="text-gray-500">{userData?.premisesLatLon}</p>
            </div>
          </div>
          <div className="basis-full md:basis-[30%] grow">
            <div className="flex item-center justify-between">
              <p className="text-gray-700">Nearest Weather Station:</p> <p className="text-gray-500 ">{userData?.nearestWeatherStation}</p>
            </div>
          </div>
          <div className="basis-full md:basis-[30%] grow">
          </div>
        </div>
        <h2 className="text-gray-800 text-xl mb-4">Equipment Details</h2>
        <div className="grid grid-cols-12 gap-6 mb-8">
          {userData?.equipment?.map((item, index) => {
            return (
              <div key={index} className="md:col-span-4 col-span-12 bg-[#f5f8fa] py-4 rounded-xl">
                <p className="bg-white my-2 rounded-r-full py-1 px-6 text-sm w-min whitespace-nowrap text-black">Id:  {item.id}</p>
                <div className="px-6">
                  {item.equipmentType && <div className="flex justify-between items-center my-2"><p className="text-gray-700">Type: </p><p className="text-gray-500">{item.equipmentType}</p></div>}
                  {item.type && <div className="flex justify-between items-center my-2"><p className="text-gray-700">Type: </p><p className="text-gray-500">{item.type}</p></div>}
                  <div className="flex justify-between items-center my-2"><p className="text-gray-700">Brand: </p><p className="text-gray-500">{item.brand}</p></div>
                  <div className="flex justify-between items-center my-2"><p className="text-gray-700">Model Name: </p><p className="text-gray-500">{item.modelName}</p></div>
                  <div className="flex justify-between items-center my-2"><p className="text-gray-700">Unique Id: </p><p className="text-gray-500">{item.uniqueId}</p></div>
                </div>
              </div>
            )
          })}
        </div>
        <h2 className="text-gray-800 text-xl mb-4">Energy Provider</h2>
        <div className="flex gap-x-10 gap-y-6 flex-wrap">
          <div className="basis-[100%] md:basis-[35%]">
            <div className="flex item-center justify-between">
              <p className="text-gray-700">Provider Name :</p> <p className="text-gray-500">{octopus}</p>
            </div>
          </div>
          <div className="basis-[100%] md:basis-[35%]">
            <div className="flex item-center justify-between">
              <p className="text-gray-700">Type :</p> <p className="text-gray-500">API or Tariff</p>
            </div>
          </div>
          <div className="basis-[100%] md:basis-[35%]"></div>
          <div className="basis-[100%] md:basis-[35%]">
            <div className="flex item-center justify-between">
              <p className="text-gray-700">Energy Provider Tariff :</p> <p className="text-gray-500">{userData?.energyProviderTariff}</p>
            </div>
          </div>
        </div>
      </div>
    </CommonComp>
  );
};

export default User;
