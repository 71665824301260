import React, { useEffect, useState } from "react";
import CommonComp from "./CommonComp";
import PylonImage from "../../images/MaximiseMyEnergy_icons/Pylon.svg";
import BatteryImage from "../../images/MaximiseMyEnergy_icons/Battery.svg";
import Pv from "../../images/MaximiseMyEnergy_icons/01d.svg";
import Solar from "../../images/MaximiseMyEnergy_icons/Solar panel.svg";
import Ac from "../../images/MaximiseMyEnergy_icons/UK Plug.svg";
import { HorizontalTicker, VerticalTicker } from "react-infinite-ticker";
const RealTimeUsage = ({ liveData }) => {
  const [charging, setCharging] = useState(0)
  useEffect(() => {
    setCharging(parseInt(liveData.batteryPowerOutW))
  }, [liveData.batteryPowerOutW])
  useEffect(() => {
    setCharging(parseInt(liveData.batteryPowerInW))
  }, [liveData.batteryPowerInW])
  return (
    <CommonComp
      title="Real Time Usage"
      textColor="text-brand-blue"
      bgColor="heroBg"
      id="realTimeUsage"
    >
      <div className="grid grid-cols-12 gap-6">
        <div className="col-span-12 md:col-span-6 xl:col-span-8">
          <div className="grid grid-cols-12 grid-rows-4 content-start gap-y-6 sm:grid-rows-5">
            <div className="col-start-1 col-end-5 row-start-1 row-end-3">
              <div className="flex flex-col justify-between rounded-lg border-2 border-gray-200 bg-white pt-3">
                <div className="px-1 sm:px-4">
                  <div className="item-center mb-4 flex flex-wrap justify-between">
                    <img src={PylonImage} alt="" width="40px" />
                    <p className="text-md mb-2 sm:text-xl">
                      {parseInt(liveData.inputPower).toLocaleString("en-US")}W
                    </p>
                  </div>
                </div>
                <div className="h-6 w-full rounded-b-lg bg-[#f4ffe9]"></div>
              </div>
            </div>
            <div className="col-start-5 col-end-9 row-start-1 row-end-3">
              <div className="flex h-full items-center bg-white">
                <div className="relative w-full">
                  <div className="flex w-full justify-between">
                    {/* this for grid  */}
                    <div
                      className="z-20 h-[6px] border-y border-r border-blue-100"
                      style={{ width: "calc(50% - 15px)" }}
                    >
                      {parseInt(liveData.inputPower) !== 0 && (
                        <HorizontalTicker
                          //reverse true for (grid to logo) and false for (logo to grid)
                          reverse={
                            parseInt(liveData.inputPower) > 0 ? true : false
                          }
                          duration={8000}
                          className="firstAnimationCome flex w-max items-center"
                        >
                          <div className="simple_dots ml-6 h-[6px] w-4 bg-brand-green"></div>
                          <div className="simple_dots ml-6 h-[6px] w-4 bg-brand-green"></div>
                          <div className="simple_dots ml-6 h-[6px] w-4 bg-brand-green"></div>
                          <div className="simple_dots ml-6 h-[6px] w-4 bg-brand-green"></div>
                          <div className="simple_dots ml-6 h-[6px] w-4 bg-brand-green"></div>
                          <div className="simple_dots ml-6 h-[6px] w-4 bg-brand-green"></div>
                        </HorizontalTicker>
                      )}
                    </div>
                    {/* this for ac  */}
                    <div
                      className="z-20 h-[6px] border-y border-l border-blue-100"
                      style={{ width: "calc(50% - 15px)" }}
                    >
                      {parseInt(liveData.outputPower) > 0 && (
                        <HorizontalTicker
                          //reverse true for (logo to ac) and false for (ac to logo)
                          reverse={
                            parseInt(liveData.outputPower) > 0 ? true : false
                          }
                          duration={8000}
                          className="firstAnimationCome flex w-max items-center"
                        >
                          <div className="simple_dots ml-6 h-[6px] w-4 bg-brand-green"></div>
                          <div className="simple_dots ml-6 h-[6px] w-4 bg-brand-green"></div>
                          <div className="simple_dots ml-6 h-[6px] w-4 bg-brand-green"></div>
                          <div className="simple_dots ml-6 h-[6px] w-4 bg-brand-green"></div>
                          <div className="simple_dots ml-6 h-[6px] w-4 bg-brand-green"></div>
                          <div className="simple_dots ml-6 h-[6px] w-4 bg-brand-green"></div>
                        </HorizontalTicker>
                      )}
                    </div>
                  </div>
                  <p className="absolute top-11 left-1/2 z-[25] w-[120px] translate-x-[-50%] rounded-md border bg-[#f7fcfe] py-1 px-2 text-sm sm:top-12 sm:w-[150px] md:w-[100px] lg:w-fit">
                    <img
                      src={require("../../images/logo/logo.png")}
                      className="h-8 object-contain sm:h-14"
                      alt=""
                    />
                  </p>
                  <div className="absolute top-[5px] left-1/2 z-[21] flex translate-x-[-50%] gap-[30px]">
                    {/* this for grid  */}
                    <div className=" h-[65px] w-[6px] border-x border-t border-blue-100  border-t-white sm:h-[43px]">
                      {parseInt(liveData.inputPower) !== 0 && (
                          <VerticalTicker
                            //reverse true for down and false for up
                            reverse={parseInt(liveData.inputPower) > 0 ? true : false}
                            duration={7000}
                            className="firstAnimationCome w-max"
                          >
                            <div className="simple_dots mb-6 h-4 w-[6px] bg-brand-green"></div>
                            <div className="simple_dots mb-6 h-4 w-[6px] bg-brand-green"></div>
                            <div className="simple_dots mb-6 h-4 w-[6px] bg-brand-green"></div>
                            <div className="simple_dots mb-6 h-4 w-[6px] bg-brand-green"></div>
                            <div className="simple_dots mb-6 h-4 w-[6px] bg-brand-green"></div>
                            <div className="simple_dots mb-6 h-4 w-[6px] bg-brand-green"></div>
                          </VerticalTicker>
                        )}
                    </div>
                    {/* this for ac  */}
                    <div className=" h-[65px] w-[6px] border-x border-t border-blue-100  border-t-white sm:h-[43px]">
                      {parseInt(liveData.outputPower) > 0 && (
                          <VerticalTicker
                            //reverse true for down and false for up
                            reverse={parseInt(liveData.outputPower) > 0 ? false : true}
                            duration={7000}
                            className="firstAnimationCome w-max"
                          >
                            <div className="simple_dots mb-6 h-4 w-[6px] bg-brand-green"></div>
                            <div className="simple_dots mb-6 h-4 w-[6px] bg-brand-green"></div>
                            <div className="simple_dots mb-6 h-4 w-[6px] bg-brand-green"></div>
                            <div className="simple_dots mb-6 h-4 w-[6px] bg-brand-green"></div>
                            <div className="simple_dots mb-6 h-4 w-[6px] bg-brand-green"></div>
                            <div className="simple_dots mb-6 h-4 w-[6px] bg-brand-green"></div>
                          </VerticalTicker>
                        )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-start-9 col-end-13 row-start-1 row-end-3">
              <div className="flex flex-col justify-between rounded-lg border-2 border-gray-200 bg-white pt-3">
                <div className="px-1 sm:px-4">
                  <div className="item-center mb-4 flex flex-wrap justify-between">
                    <img src={Ac} width="40px" alt="" />
                    <p className="text-md mb-2 sm:text-xl">
                      {parseInt(liveData.outputPower).toLocaleString("en-US")} W
                    </p>
                  </div>
                </div>
                <div className="h-6 w-full bg-[#daeaef]"></div>
              </div>
            </div>
            <div className="relative col-start-1 col-end-5 row-start-3 row-end-5 sm:row-end-6">
              <div className="flex flex-col justify-between rounded-lg border-2 border-gray-200 bg-white pt-3">
                <div className="px-1 sm:px-4">
                  <div className="flex flex-wrap justify-between text-sm sm:text-lg">
                    <p className="mb-2 flex items-center gap-1">
                      <span>
                        <img src={BatteryImage} alt="" width="40px" />
                      </span>{" "}
                      {liveData.batterySoc > 0 ? "Charging" : ""}
                    </p>
                    <p>
                      {(
                        parseInt(liveData.batteryPowerOutW) -
                        parseInt(liveData.batteryPowerInW)
                      ).toLocaleString("en-US")}{" "}
                      W
                    </p>
                  </div>
                  <p
                    className={`blob text-sm sm:text-xl ${liveData.batterySoc > 40 ? "bg-[#d5f4b6]" : "bg-[#daeaef]"
                      } mb-2 w-fit rounded-md py-1 px-3`}
                  >
                    {liveData.batterySoc}%
                  </p>
                </div>
                <div
                  className={`h-6 w-full  ${liveData.batterySoc > 40 ? "bg-[#d5f4b6]" : "bg-[#daeaef]"
                    }`}
                ></div>
              </div>
            </div>
            <div className="col-start-5 col-end-9 row-start-3 row-end-5 sm:row-end-6">
              <div className="flex h-full items-center bg-white">
                <div className="relative w-full">
                  <div className="flex w-full justify-between">
                    <div
                      className="z-20 h-[6px] border-y border-r border-blue-100"
                      style={{ width: "calc(50% - 15px)" }}
                    >
                      {charging !== 0 && (
                        <HorizontalTicker
                          //reverse true for (battery to logo) and false for (logo to battery)
                          reverse={charging < 0 ? true : false}
                          duration={8000}
                          className="firstAnimationCome flex w-max items-center"
                        >
                          <div className="simple_dots ml-6 h-[6px] w-4 bg-brand-green"></div>
                          <div className="simple_dots ml-6 h-[6px] w-4 bg-brand-green"></div>
                          <div className="simple_dots ml-6 h-[6px] w-4 bg-brand-green"></div>
                          <div className="simple_dots ml-6 h-[6px] w-4 bg-brand-green"></div>
                          <div className="simple_dots ml-6 h-[6px] w-4 bg-brand-green"></div>
                          <div className="simple_dots ml-6 h-[6px] w-4 bg-brand-green"></div>
                        </HorizontalTicker>
                      )}
                    </div>
                    <div
                      className="z-20 h-[6px] border-y border-l border-blue-100"
                      style={{ width: "calc(50% - 15px)" }}
                    >
                      {parseInt(liveData.pvW) > 0 && (
                        <HorizontalTicker
                          //reverse true for right and false for left
                          reverse={(parseInt(liveData.pvW) > 0 ? false : true)}
                          duration={8000}
                          className="firstAnimationCome flex w-max items-center"
                        >
                          <div className="simple_dots mr-6 h-[6px] w-4 bg-brand-green"></div>
                          <div className="simple_dots mr-6 h-[6px] w-4 bg-brand-green"></div>
                          <div className="simple_dots mr-6 h-[6px] w-4 bg-brand-green"></div>
                          <div className="simple_dots mr-6 h-[6px] w-4 bg-brand-green"></div>
                          <div className="simple_dots mr-6 h-[6px] w-4 bg-brand-green"></div>
                          <div className="simple_dots mr-6 h-[6px] w-4 bg-brand-green"></div>
                        </HorizontalTicker>
                      )}
                    </div>
                  </div>
                  <div className="absolute bottom-[5px] left-1/2 z-[21] flex translate-x-[-50%] gap-[30px]">
                    <div className=" h-[65px] w-[6px] border-x border-b border-blue-100 border-b-white sm:h-[43px]">
                      {charging !== 0 && (
                          <VerticalTicker
                            //reverse true for down and false for up
                            reverse={charging < 0 ? false : true}
                            duration={7000}
                            className="firstAnimationCome w-max"
                          >
                            <div className="simple_dots mb-6 h-4 w-[6px] bg-brand-green"></div>
                            <div className="simple_dots mb-6 h-4 w-[6px] bg-brand-green"></div>
                            <div className="simple_dots mb-6 h-4 w-[6px] bg-brand-green"></div>
                            <div className="simple_dots mb-6 h-4 w-[6px] bg-brand-green"></div>
                            <div className="simple_dots mb-6 h-4 w-[6px] bg-brand-green"></div>
                            <div className="simple_dots mb-6 h-4 w-[6px] bg-brand-green"></div>
                          </VerticalTicker>
                        )}
                    </div>
                    <div className=" h-[65px] w-[6px] border-x border-b border-blue-100 border-b-white sm:h-[43px]">
                      {parseInt(liveData.pvW) > 0 && (
                          <VerticalTicker
                            //reverse true for down and false for up
                            reverse={(parseInt(liveData.pvW) > 0 ? false : true)}
                            duration={7000}
                            className="firstAnimationCome w-max"
                          >
                            <div className="simple_dots mb-6 h-4 w-[6px] bg-brand-green"></div>
                            <div className="simple_dots mb-6 h-4 w-[6px] bg-brand-green"></div>
                            <div className="simple_dots mb-6 h-4 w-[6px] bg-brand-green"></div>
                            <div className="simple_dots mb-6 h-4 w-[6px] bg-brand-green"></div>
                            <div className="simple_dots mb-6 h-4 w-[6px] bg-brand-green"></div>
                            <div className="simple_dots mb-6 h-4 w-[6px] bg-brand-green"></div>
                          </VerticalTicker>
                        )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="relative col-start-9 col-end-13 row-start-3 row-end-5 sm:row-end-6">
              {/* <div className="rounded-md w-fit border bg-[#f7fcfe] mb-2 py-2 px-4 text-sm">Ext Control</div> */}
              <div className="flex flex-col justify-between rounded-lg border-2 border-gray-200 bg-white pt-3">
                <div className="px-1 sm:px-4">
                  <div className="flex justify-between">
                    <p className="mb-2 flex items-center gap-1 text-sm sm:text-lg">
                      <span>
                        <img src={Pv} alt="" width="40px" />
                      </span>
                      PV Charger
                    </p>
                  </div>
                  <p className="mb-2 w-fit rounded-md bg-[#fef7ec] py-1 px-3 text-sm sm:text-xl">
                    {parseInt(liveData.pvW).toLocaleString("en-US")}W
                  </p>
                </div>
                <div className="h-6 w-full bg-[#fef7ec]"></div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-span-12 md:col-span-6 xl:col-span-4">
          <div>
            <div className="mb-2 flex w-full items-center justify-between rounded-md border border-gray-300 bg-white py-2 px-3">
              <h2 className=" text-md flex items-center gap-2 text-gray-500">
                <img src={PylonImage} width="30px" alt="" />
                Grid
              </h2>
              <p className=" text-md text-gray-500">
                {parseInt(liveData.inputPower).toLocaleString("en-US")} W
              </p>
            </div>
            <div className="mb-2 flex w-full items-center justify-between rounded-md border border-gray-300 bg-white py-2 px-3">
              <h2 className=" text-md flex items-center gap-2 text-gray-500">
                <img src={Ac} alt="" width="30px" />
                Load
              </h2>
              <p className=" text-md text-gray-500">
                {parseInt(liveData.outputPower).toLocaleString("en-US")} W
              </p>
            </div>
            <div className="mb-2 flex w-full items-center justify-between rounded-md border border-gray-300 bg-white py-2 px-3">
              <h2 className=" text-md flex items-center gap-2 text-gray-500">
                <img src={Solar} alt="" width={"30px"} />
                Solar
              </h2>
              <p className=" text-md text-gray-500">
                {parseInt(liveData.pvW).toLocaleString("en-US")} W
              </p>
            </div>
            <div className="mb-2 flex w-full items-center justify-between rounded-md border border-gray-300 bg-white py-2 px-3">
              <h2
                className={`text-md flex items-center gap-2 ${liveData.batterySoc > 40 ? "text-brand-green" : "text-red-600"
                  }`}
              >
                <img src={BatteryImage} alt="" width={"30px"} />
                Battery
              </h2>
              <p className=" text-md text-gray-500">{liveData.batterySoc} %</p>
            </div>
          </div>
        </div>
      </div>
    </CommonComp>
  );
};
export default RealTimeUsage;
