import React, { useEffect, useState } from "react";
import CommonComp from "./CommonComp";
import Collapse, { Panel } from "rc-collapse";
import "rc-collapse/assets/index.css";
const HistoricalUsage = ({ authId }) => {
  let auth0Id = localStorage.getItem("userId");
  const apiName =
    process.env.NODE_ENV === "production"
      ? process.env.REACT_APP_PRODUCTION_API_URL
      : process.env.REACT_APP_DEV_API_URL;
  const [historicalUsage, setHistoricalUsage] = useState();
  const [historicalUsageArr, setHistoricalUsageArr] = useState([]);
  const [everyUsageObj, setEveryUsageObj] = useState([]);
  const [arrOfdes, setArrOfdes] = useState([]);
  useEffect(() => {
    // ** this is for main header
    const allUsageEndPoints = [
      {
        endPoint: `${apiName}/historical-usage2.php?lookback=previous_hour&user_id=${auth0Id}`,
        name: "pervious_hournew",
      },
      {
        endPoint: `${apiName}/historical-usage2.php?lookback=today&user_id=${auth0Id}`,
        name: "todaynew",
      },
      {
        endPoint: `${apiName}/historical-usage2.php?lookback=yesterday&user_id=${auth0Id}`,
        name: "yesterday",
      },
      {
        endPoint: `${apiName}/historical-usage2.php?lookback=this_week&user_id=${auth0Id}`,
        name: "thisWeek",
      },
      {
        endPoint: `${apiName}/historical-usage2.php?lookback=last_week&user_id=${auth0Id}`,
        name: "lastWeek",
      },
      {
        endPoint: `${apiName}/historical-usage2.php?lookback=last_month&user_id=${auth0Id}`,
        name: "lastMonth",
      },
      {
        endPoint: `${apiName}/historical-usage2.php?lookback=this_month&user_id=${auth0Id}`,
        name: "thisMonth",
      },
      {
        endPoint: `${apiName}/historical-usage2.php?lookback=this_year&user_id=${auth0Id}`,
        name: "thisYear",
      },
      {
        endPoint: `${apiName}/historical-usage2.php?lookback=last_year&user_id=${auth0Id}`,
        name: "lastYear",
      },
    ];

    const allHistoricalApiHandler = async () => {
      const responses = await Promise.all(
        allUsageEndPoints.map((url) => fetch(url.endPoint))
      );

      const jsonData = await Promise.all(
        responses.map((response) => response.json())
      );

      const newData = await Promise.all(
        jsonData.map((item) => item.customer_data)
      );
      const arrayOfAllobjTable = []
      newData.map((item) => {
        const dataTable = (
          <div>
            <div className="grid grid-cols-12 border-b border-gray-200">
              <div className="col-span-5 py-3 px-2"></div>
              <div className="myCen col-span-4 py-3 px-2 font-bold">
                ENERGY kWh
              </div>
              <div className="col-span-3"></div>
            </div>
            {Object.keys(item.kwh).map((kwhItem) => (
              <div className="grid grid-cols-12 border-b border-gray-200">
                <div className="col-span-5 py-3 px-2">{kwhItem}</div>
                <div className="myCen col-span-4 py-3 px-2 font-bold md:font-normal">
                  {item.kwh[kwhItem]?.kwh}
                </div>
                <div className="col-span-3"></div>
              </div>
            ))}
            <div className="grid grid-cols-12 border-b border-gray-200">
              <div className="col-span-5 py-3 px-2"></div>
              <div className="myCen col-span-4 py-3 px-2 font-bold"></div>
              <div className="col-span-3 myCen py-3 px-2 font-bold">£ COST</div>
            </div>
            {Object.keys(item.kwh_cost).map((kwhItem) => (
              <div className="grid grid-cols-12 border-b border-gray-200">
                <div className="col-span-5 py-3 px-2">{kwhItem}</div>
                <div className="myCen col-span-4 py-3 px-2 font-bold md:font-normal">
                  {item.kwh_cost[kwhItem]?.kwh}
                </div>
                <div className="col-span-3 py-3 px-2 font-bold md:font-normal myCen">
                  {item.kwh_cost[kwhItem]?.cost}
                </div>
              </div>
            ))}
            {Object.keys(item.cost).map((kwhItem) => (
              <div className="grid grid-cols-12 border-b border-gray-200">
                <div className="col-span-5 py-3 px-2">{kwhItem}</div>
                <div className="myCen col-span-4 py-3 px-2 font-bold md:font-normal">
                  {item.cost[kwhItem]?.kwh}
                </div>
                <div className="col-span-3 py-3 px-2 font-bold md:font-normal myCen">
                  {item.cost[kwhItem]?.cost}
                </div>
              </div>
            ))}
          </div>
        );
        arrayOfAllobjTable.push(dataTable);
       return(
          <></>
        )
      })
      setEveryUsageObj(newData);
      setArrOfdes(arrayOfAllobjTable);
    };
    allHistoricalApiHandler();

    async function MainHeaderApi() {
      try {
        const response = await fetch(
          `${apiName}/historical-usage.php?user_id=${auth0Id}`,
          {
            method: "GET",
            redirect: "follow",
          }
        );
        if (!response.ok) {
          throw new Error("something went wrong");
        }
        const data = await response.json();
        setHistoricalUsage(data.available_params.lookback);
        setHistoricalUsageArr(Object.keys(data.available_params.lookback));
      } catch (error) {
        console.log(error.message);
      }
    }
    MainHeaderApi();
    //** end here
  }, [auth0Id, apiName]);
  const newnew = historicalUsageArr?.map((item, index) => ({
    name: historicalUsage[item],
    biography: arrOfdes[index],
  }));
  const [openTab, setOpenTab] = useState(0);
  const [screen, setScreen] = useState();
  useEffect(() => {
    const CurrentWidth = window.innerWidth;
    setScreen(CurrentWidth);
    window.addEventListener("resize", function () {
      const CurrentWidth = window.innerWidth;
      setScreen(CurrentWidth);
    });
  }, [auth0Id]);
  return (
    <CommonComp
      title="Energy Dashboard"
      textColor="text-brand-blue"
      bgColor="historicalBg"
      id="historical"
    >
      {/* I render two different components according to there screen size  */}
      {screen > 768 ? (
        <div className="relative flex flex-wrap">
          <div className="sideMenu_h flex w-full overflow-scroll bg-brand-blue py-4 px-4 md:block md:overflow-auto md:py-10 lg:rounded-l-xl">
            {historicalUsageArr.map((item, index) => {
              return (
                <div key={index} className="w-min md:w-full">
                  <button
                    className={
                      "my-2 whitespace-nowrap rounded-lg py-2 px-3 text-center text-[14px] font-medium duration-150 md:w-full md:whitespace-normal " +
                      (openTab === index
                        ? "bg-white text-brand-blue"
                        : "text-white")
                    }
                    onClick={(e) => {
                      e.preventDefault();
                      setOpenTab(index);
                    }}
                  >
                    {historicalUsage[item]}
                  </button>
                </div>
              );
            })}
          </div>
          <div className="sideTable w-full overflow-hidden border border-gray-200 shadow-lg lg:rounded-r-xl">
            {everyUsageObj.map((obj, index) => {
              return (
                <div
                  key={index}
                  className={
                    "w-full" + (openTab === index ? " block" : " hidden")
                  }
                >
                  <div className="grid grid-cols-12 border-b border-gray-200">
                    <div className="col-span-1 py-3 px-2"></div>
                    <div className="col-span-6 py-3 px-2"></div>
                    <div className="myCen col-span-2 py-3 px-2">ENERGY kWh</div>
                    <div className="costS myCen col-span-2 py-3 px-2"></div>
                    <div className="col-span-1 py-3 px-2"></div>
                  </div>

                  {Object.keys(obj?.kwh).map((item, index) => {
                    return (
                      <div
                        className="grid grid-cols-12 border-b border-gray-200"
                        key={index}
                      >
                        <div className="col-span-1 py-3 px-2"></div>
                        <div className="col-span-6 py-3 px-2">{item}</div>
                        <div className="myCen col-span-4 py-3 px-2 md:col-span-2">
                          {obj?.kwh[item]?.kwh
                            ? obj?.kwh[item]?.kwh.toFixed(2)
                            : ""}
                        </div>
                        <div className="costS col-span-2 py-3 px-2 text-center">
                          {obj?.kwh[item]?.cost
                            ? "£ " + obj?.kwh[item]?.cost.toFixed(2)
                            : ""}
                        </div>
                        <div className="col-span-1 py-3 px-2"></div>
                      </div>
                    );
                  })}
                  <div className="grid grid-cols-12 border-b border-gray-200">
                    <div className="col-span-1 py-3 px-2"></div>
                    <div className="col-span-6 py-3 px-2"></div>
                    <div className="myCen col-span-2 py-3 px-2"></div>
                    <div className="costS myCen col-span-2 py-3 px-2">
                      £ COST
                    </div>
                    <div className="col-span-1 py-3 px-2"></div>
                  </div>
                  {Object.keys(obj?.kwh_cost).map((item, index) => {
                    return (
                      <div
                        className="grid grid-cols-12 border-b border-gray-200"
                        key={index}
                      >
                        <div className="col-span-1 py-3 px-2"></div>
                        <div className="col-span-6 py-3 px-2">{item}</div>
                        <div className="myCen col-span-4 py-3 px-2 md:col-span-2">
                          {obj?.kwh_cost[item]?.kwh
                            ? obj?.kwh_cost[item]?.kwh.toFixed(2)
                            : ""}
                        </div>
                        <div className="costS col-span-2 py-3 px-2 text-center">
                          {obj?.kwh_cost[item]?.cost
                            ? "£ " + obj?.kwh_cost[item]?.cost.toFixed(2)
                            : ""}
                        </div>
                        <div className="col-span-1 py-3 px-2"></div>
                      </div>
                    );
                  })}
                  {Object.keys(obj?.cost).map((item, index) => {
                    if (item === "Subscription Fee") {
                      return (
                        <>
                          <div
                            className="grid grid-cols-12 border-b border-gray-200"
                            key={index}
                          >
                            <div className="col-span-1 py-3 px-2"></div>
                            <div className="col-span-6 py-3 px-2">{item}</div>
                            <div className="myCen col-span-4 py-3 px-2 md:col-span-2">
                              {obj?.cost[item]?.kwh
                                ? obj?.cost[item]?.kwh.toFixed(2)
                                : ""}
                            </div>
                            <div className="costS col-span-2 py-3 px-2 text-center">
                              {obj?.cost[item]?.cost
                                ? "£ " + obj?.cost[item]?.cost.toFixed(2)
                                : ""}
                            </div>
                            <div className="col-span-1 py-3 px-2"></div>
                          </div>
                          <div
                            className="grid grid-cols-12 border-b border-gray-200"
                            key={index}
                          >
                            <div className="col-span-1 py-3 px-2"></div>
                            <div className="col-span-6 select-none py-3 px-2 text-transparent">
                              maximise my energy
                            </div>
                            <div className="myCen col-span-4 py-3 px-2 md:col-span-2">
                              {""}
                            </div>
                            <div className="costS col-span-2 py-3 px-2 text-center">
                              {""}
                            </div>
                            <div className="col-span-1 py-3 px-2"></div>
                          </div>
                        </>
                      );
                    }
                    if (item === "Without maximisemy.energy") {
                      return <></>;
                    } else {
                      return (
                        <div
                          className="grid grid-cols-12 border-b border-gray-200"
                          key={index}
                        >
                          <div className="col-span-1 py-3 px-2"></div>
                          <div className="col-span-6 py-3 px-2">{item}</div>
                          <div className="myCen col-span-4 py-3 px-2 md:col-span-2">
                            {obj?.cost[item]?.kwh
                              ? obj?.cost[item]?.kwh.toFixed(2)
                              : ""}
                          </div>
                          <div className="costS col-span-2 py-3 px-2 text-center">
                            {obj?.cost[item]?.cost
                              ? "£ " + obj?.cost[item]?.cost.toFixed(2)
                              : ""}
                          </div>
                          <div className="col-span-1 py-3 px-2"></div>
                        </div>
                      );
                    }
                  })}
                </div>
              );
            })}
          </div>
        </div>
      ) : (
        <div className="">
          {/* <Tabs transformWidth="450" items={getTabs()} /> */}
          <div className="mx-auto w-full">
            <Collapse accordion={true} defaultActiveKey={["0"]}>
              {newnew?.map((item, index) => {
                return (
                  <Panel
                    key={index}
                    header={item.name}
                    headerClass="my-header-class"
                  >
                    {item.biography}
                  </Panel>
                );
              })}
            </Collapse>
          </div>
        </div>
      )}
      <div></div>
    </CommonComp>
  );
};
export default HistoricalUsage;
