import React from 'react'
import { Link } from 'react-router-dom'

const Four0Four = () => {
    return (
        <section className='bg404'>
            <div className="container mx-auto min-h-screen flex items-center justify-center flex-col">
                <img src={require("../images/four0four.png")} alt="" className='max-w-md w-10/12 mb-4' />
                <h1 className='text-center text-4xl font-bold mb-2'>SORRY...THIS PAGE NOT FOUND</h1>
                <p className='text-center text-lg mb-4'>The page you were looking for doesn't exist anymore.</p>
                <Link to="/" className='flex items-center justify-center rounded-full border border-brand-blue px-4 py-2 text-sm font-medium text-white bg-brand-blue shadow-sm'>
                 Back To Home
                </Link>
            </div>
        </section>
    )
}

export default Four0Four