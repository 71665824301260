import React from "react";
import ReactDOM from "react-dom/client";
import "./index.scss";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { Auth0Provider } from "@auth0/auth0-react";

const clientId = process.env.NODE_ENV === "production" ? process.env.REACT_APP_PRODUCTION_AUTH0_CLIENT_ID : process.env.REACT_APP_DEV_AUTH0_CLIENT_ID;
const domain = process.env.NODE_ENV === "production" ? process.env.REACT_APP_PRODUCTION_AUTH0_DOMAIN : process.env.REACT_APP_DEV_AUTH0_DOMAIN;
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Auth0Provider
    domain={domain}
    clientId={clientId}
    authorizationParams={{
      redirect_uri: window.location.origin+"/authVerify",
    }}
    useRefreshTokens={false}
    cacheLocation="localstorage"
  >
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Auth0Provider>
);
reportWebVitals();
