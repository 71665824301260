import React from "react";

const DailyPlan = (props) => {
  var d = new Date();
  const totalValue = (props?.uses + props?.solar + props?.importT + props?.exportT);
  return (
    <div className="h-full flex flex-col justify-between">
      <div>
        <h2 className="text-2xl mb-2">Daily Plan</h2>
        <p>Scheduled import and export timing for {d.toDateString()}</p>
      </div>
      <div className="pb-[7%]">
        <div className="border-b-2 border-gray-300 px-2 md:px-6  h-60 flex items-end justify-between gap-2 md:gap-2">
          <div className="bg-[#8faadc] flex items-center p-2 justify-center rounded-t-md cursor-pointer relative bar-graph" style={{ height: (props?.uses / totalValue) * 100 + "%", width: "25%" }}>
            <p className="text-xs md:text-sm text-center">{props.uses}kWh</p>
            <p className="label absolute -top-14 bg-white shadow-md border border-gray-200 rounded-md px-4 py-2">{props.uses}kWh</p>
          </div>
          <div className="bg-[#ffd966] flex items-center p-2 justify-center rounded-t-md cursor-pointer relative bar-graph" style={{ height: (props?.solar / totalValue) * 100 + "%", width: "25%" }}>
            <p className="text-xs md:text-sm text-center">{props.solar}kWh</p>
            <p className="label absolute -top-14 bg-white shadow-md border border-gray-200 rounded-md px-4 py-2">{props.solar}kWh</p>
          </div>
          <div className="bg-[#f4b183] flex items-center p-2 justify-center rounded-t-md cursor-pointer relative bar-graph" style={{ height: (props?.importT / totalValue) * 100 + "%", width: "25%" }}>
            <p className="text-xs md:text-sm text-center">{props.importT}kWh</p>
            <p className="label absolute -top-14 bg-white shadow-md border border-gray-200 rounded-md px-4 py-2">{props.importT}kWh</p>
          </div>
          <div className="bg-[#a9d18e] flex items-center p-2 justify-center rounded-t-md cursor-pointer relative bar-graph" style={{ height: (props?.exportT / totalValue) * 100 + "%", width: "25%" }}>
            <p className="text-xs md:text-sm text-center">{props.exportT}kWh</p>
            <p className="label absolute -top-14 bg-white shadow-md border border-gray-200 rounded-md px-4 py-2">{props.exportT}kWh</p>
          </div>
        </div>
        <div className="flex items-end gap-2 md:gap-2 px-2 md:px-6 ">
            <p className="w-[25%] text-xs md:text-sm flex gap-1 items-center justify-center"><span className="min-w-2 w-2 h-2 bg-[#8faadc]"></span> Usage</p>
            <p className="w-[25%] text-xs md:text-sm flex gap-1 items-center justify-center"><span className="min-w-2 w-2 h-2 bg-[#ffd966]"></span> Solar Generation</p>
            <p className="w-[25%] text-xs md:text-sm flex gap-1 items-center justify-center"><span className="min-w-2 w-2 h-2 bg-[#f4b183]"></span> Import</p>
            <p className="w-[25%] text-xs md:text-sm flex gap-1 items-center justify-center"><span className="min-w-2 w-2 h-2 bg-[#a9d18e]"></span> Export</p>
        </div>
      </div>
    </div>
  );
};

export default DailyPlan;
