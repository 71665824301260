import React, { useEffect, useState } from "react";
import Chart from "react-google-charts";
import CommonComp from "./CommonComp";
import DailyPlan from "./energy/DailyPlan";
const Energy = ({ authId }) => {
  let auth0Id = localStorage.getItem("userId");
  const apiName = process.env.NODE_ENV === "production" ? process.env.REACT_APP_PRODUCTION_API_URL : process.env.REACT_APP_DEV_API_URL;
  const [time, setTime] = useState([]);
  const [todayPoint, setTodayPont] = useState([]);
  const [TomorrowPoint, setTomorrowPoint] = useState([]);
  const [energyTariffPrice, setEnergyTariffPrice] = useState([]);
  const [energyUsageForecast, setEnergyUsageForecast] = useState([]);
  const [showData, setShowData] = useState(false);
  // this area is for daily Plan
  const [ie, setie] = useState({ uses: 0, solar: 0, importT: 0, exportT: 0 });
  useEffect(() => {
    const dailyPlan = async () => {
      try {
        const response1 = await fetch(
          `${apiName}/energy-generation-forecast-today.php?user_id=${auth0Id}`,
          {
            method: "GET",
            // headers: apiHeader(authId),
            redirect: "follow",
          }
        );
        const response2 = await fetch(
          `${apiName}/solar-forecast.php?user_id=${auth0Id}`,
          {
            method: "GET",
            // headers: apiHeader(authId),
            redirect: "follow",
          }
        );
        const response3 = await fetch(
          `${apiName}/schedule-today.php?user_id=${auth0Id}`,
          {
            method: "GET",
            // headers: apiHeader(authId),
            redirect: "follow",
          }
        );
        if (!response1.ok || !response2 || !response3) {
          throw new Error("something went wrong");
        }
        const data1 = await response1.json();
        const data2 = await response2.json();
        const data3 = await response3.json();
        let totalUsages = 0;
        let Todayimport = 0;
        let Todayexport = 0;
        Object.keys(data1.customer_data).map((item) => {
          totalUsages += data1.customer_data[item];
          Todayimport +=
            data3.customer_data[item] > 0 ? data3.customer_data[item] : 0;
          Todayexport +=
            data3.customer_data[item] < 0 ? data3.customer_data[item] : 0;
          return(<></>) ;
        });
        let removeMin = Todayexport.toString().substring(1);
        setie({
          uses: totalUsages/1000,
          solar: data2.customer_data["Estimated Energy Production - Today"],
          importT: Todayimport/1000,
          exportT: parseFloat(removeMin)/1000,
        });
      } catch (error) {
        console.log(error.message);
      }
    };
    dailyPlan();
  }, [auth0Id, apiName]);
  // end here

  // this area is for today Graph
  const [todayGraphData, SetTodayGraphData] = useState([
    ["time", "schedule Today"],
    ["00:00", 0, 0, 0],
  ]);
  const [impExp, setImpExp] = useState([
    ["time", "schedule Today"],
    ["00:00", 0, 0, 0],
  ]);
  useEffect(() => {
    const TodayData = async () => {
      try {
        const response1 = await fetch(
          `${apiName}/schedule-today.php?user_id=${auth0Id}`,
          {
            method: "GET",
            // headers: apiHeader(auth0Id),
            redirect: "follow",
          }
        );
        const response2 = await fetch(
          `${apiName}/energy-tariff-today.php?user_id=${auth0Id}`,
          {
            method: "GET",
            // headers: apiHeader(auth0Id),
            redirect: "follow",
          }
        );
        const response3 = await fetch(
          `${apiName}/energy-generation-forecast-today.php?user_id=${auth0Id}`,
          {
            method: "GET",
            // headers: apiHeader(auth0Id),
            redirect: "follow",
          }
        );
        const response4 = await fetch(
          `${apiName}/energy-usage-forecast-today.php?user_id=${auth0Id}`,
          {
            method: "GET",
            // headers: apiHeader(auth0Id),
            redirect: "follow",
          }
        );
        if (!response1.ok || !response2 || !response3 || !response4) {
          throw new Error("something went wrong");
        }
        const data1 = await response1.json();
        const data2 = await response2.json();
        const data3 = await response3.json();
        const data4 = await response4.json();
        const Today = [["time", "Solar Generation", {role: 'tooltip'}, "Usage", {role: 'tooltip'}]];
        const IMEX = [["time", "schedule-today", { role: "style" }, {role: 'tooltip'}]];
        Object.keys(data1.customer_data).map((item) => {
          const TodayData =
            data1.customer_data[item] * data2.customer_data[item] < 0
              ? (data1.customer_data[item] * data2.customer_data[item])
                  .toString()
                  .substring(1)
              : data1.customer_data[item] * data2.customer_data[item];
          Today.push([
            item,
            data3.customer_data[item] / 1000,
            `Solar Generation: ${data3.customer_data[item] / 1000}kWh`,
            data4.customer_data[item] / 1000,
            `Usage: ${data4.customer_data[item] / 1000}kWh`
          ]);
          IMEX.push([
            item,
            parseFloat(TodayData),
            `${
              data1.customer_data[item] * data2.customer_data[item] >= 0
                ? "#f4b183"
                : "#a9d18e"
            }`,
            `Amount : £${data2.customer_data[item]}`
          ]);
          return(<></>) ;
        });
        // console.log(Today)
        SetTodayGraphData(Today);
        setImpExp(IMEX);
      } catch (error) {
        console.log(error.message);
      }
    };
    TodayData();
  }, [auth0Id, apiName]);
  // end here

  // this area is for tomorrow and table data
  useEffect(() => {
    // this area is for energy-tariff-tomorrow
    async function MainHeaderApi() {
      try {
        const response = await fetch(
          `${apiName}/energy-tariff-tommorrow.php?user_id=${auth0Id}`,
          {
            method: "GET",
            redirect: "follow",
          }
        );
        if (!response.ok) {
          throw new Error("something went wrong");
        }
        const data = await response.json();
        // console.log(data.customer_data);
        const tariffData = [["time", "energy-tariff-tomorrow"]];
        const dataTime = [];
        const tariff = [];
        Object.keys(data.customer_data).map((item) => {
          tariffData.push([item, data.customer_data[item]]);
          dataTime.push(item);
          tariff.push(data.customer_data[item]);
          return(<></>) ;
        });
        setTime(dataTime);
        setEnergyTariffPrice(tariff);
      } catch (error) {
        console.log(error.message);
      }
    }
    MainHeaderApi();
    // end here
    // this area is for energy-tariff-tomorrow
    async function PowerApi1() {
      try {
        const response1 = await fetch(
          `${apiName}/energy-usage-forecast-tommorrow.php?user_id=${auth0Id}`,
          {
            method: "GET",
            // headers: apiHeader(authId),
            redirect: "follow",
          }
        );
        const response2 = await fetch(
          `${apiName}/schedule-today.php?user_id=${auth0Id}`,
          {
            method: "GET",
            // headers: apiHeader(authId),
            redirect: "follow",
          }
        );
        const response3 = await fetch(
          `${apiName}/schedule-tomorrow.php?user_id=${auth0Id}`,
          {
            method: "GET",
            // headers: apiHeader(authId),
            redirect: "follow",
          }
        );
        const response4 = await fetch(
          `${apiName}/energy-tariff-tommorrow.php?user_id=${auth0Id}`,
          {
            method: "GET",
            // headers: apiHeader(authId),
            redirect: "follow",
          }
        );
        const response6 = await fetch(
          `${apiName}/energy-generation-forecast-tomorrow.php?user_id=${auth0Id}`,
          {
            method: "GET",
            // headers: apiHeader(authId),
            redirect: "follow",
          }
        );
        if (
          !response1.ok ||
          !response2.ok ||
          !response3.ok ||
          !response4 ||
          !response6
        ) {
          throw new Error("something went wrong");
        }
        const data1 = await response1.json();
        const data2 = await response2.json();
        const data3 = await response3.json();
        const data6 = await response6.json();
        const Tomorrow = [
          [
            "time",
            // "energy-tariff-tomorrow",
            // { role: "style" },
            // "schedule",
            "Solar Generation",
            "Usage",
          ],
        ];
        const todayPointData = [];
        const tomorrowPointData = [];
        const energyUsageForecastData = [];
        Object.keys(data1.customer_data).map((item) => {
          Tomorrow.push([
            item,
            // data4.customer_data[item],
            // `${data4.customer_data[item] >= 0 ? "#d5e3eb" : "#7f7f7f"}`,
            // data3.customer_data[item],
            data6.customer_data[item] / 1000,
            data1.customer_data[item] / 1000,
          ]);
          todayPointData.push(data2.customer_data[item]);
          tomorrowPointData.push(data3.customer_data[item]);
          energyUsageForecastData.push(data1.customer_data[item]);
          return(<></>) ;
        });
        setTodayPont(todayPointData);
        setTomorrowPoint(tomorrowPointData);
        setEnergyUsageForecast(energyUsageForecastData);
      } catch (error) {
        console.log(error.message);
      }
    }
    PowerApi1();
  }, [auth0Id, apiName]);
  // end here
  var d = new Date(); // for now
  d.getHours(); // => 9
  d.getMinutes(); // =>  30
  let getHourAndMinute = d.getHours() + ":" + d.getMinutes();
  return (
    <CommonComp
      title="Energy Tariffs and Schedules"
      textColor="text-brand-blue"
      id="energy"
    >
      <div className="grid grid-cols-5 gap-1">
        <div className="col-span-12 lg:col-span-2">
          <DailyPlan
            uses={ie.uses}
            solar={ie.solar}
            importT={ie.importT}
            exportT={ie.exportT}
          ></DailyPlan>
        </div>
        <div className="col-span-12 lg:col-span-3">
          <h2 className="mb-2 text-2xl">Import & Export</h2>
          <p>Scheduled import and export Today</p>
          <Chart
            chartType="ComboChart"
            data={impExp}
            options={options3}
            width="100%"
            height="400px"
          />
        </div>
      </div>
      <div></div>
      <h2 className="mb-2 text-2xl">Today</h2>
      <Chart
        chartType="ComboChart"
        data={todayGraphData}
        options={options1}
        width="100%"
        height="400px"
      />
      <div>
        <button
          onClick={() => {
            setShowData(!showData);
          }}
          className="mx-auto my-4 flex items-center justify-center rounded-full border border-brand-blue bg-brand-blue px-4 py-2 text-sm font-medium text-white shadow-sm"
        >
          {showData ? "Hide Data in Table" : "Show Data In Table"}
        </button>
        {showData && (
          <div className="relative ">
            <div className="grid grid-cols-12 gap-6">
              <div className="custom-scroll col-span-12 max-h-[400px] overflow-auto rounded-lg shadow-lg lg:col-span-4">
                <div className="sticky top-0">
                  <p className="flex items-center bg-white py-2 px-8 text-lg text-gray-800">
                    Schedule
                  </p>
                  <p className="flex items-center justify-center bg-brand-blue py-2 px-2 text-center text-lg text-white">
                    <span>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="mr-2 h-5 w-5 text-white"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 012.25-2.25h13.5A2.25 2.25 0 0121 7.5v11.25m-18 0A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75m-18 0v-7.5A2.25 2.25 0 015.25 9h13.5A2.25 2.25 0 0121 11.25v7.5"
                        />
                      </svg>
                    </span>
                    Today
                  </p>
                </div>
                <div className="px-2 sm:px-8 py-2 text-center sm:text-left">
                  <div className="grid grid-cols-2">
                    <div>
                      <p className="text-base mb-3 font-bold">Time</p>
                      {time?.map((times, index) => {
                        return (
                          <p
                            className={
                              getHourAndMinute > times
                                ? "text-gray-400 text-xs sm:text-base"
                                : "text-gray-800 text-xs sm:text-base"
                            }
                            key={index}
                          >
                            {times}
                          </p>
                        );
                      })}
                    </div>
                    <div>
                      <p className="text-base mb-3 font-bold">Buy/Sell (kWh)</p>
                      {todayPoint?.map((item, index) => {
                        // console.log(getHourAndMinute+":"+time[index])
                        return getHourAndMinute > time[index] ? (
                          <div key={index}>
                            {item >= 0 ? (
                              <p className="text-[#f4b183] text-xs sm:text-base">{(item/1000).toFixed(2)} Bought</p>
                            ) : (
                              <p className="text-[#a9d18e] text-xs sm:text-base">
                                {String((item/1000).toFixed(2)).slice(1)} Sold
                              </p>
                            )}
                          </div>
                        ) : (
                          <p className="text-blue-500" key={index}>
                            {item >= 0 ? (
                              <p className="text-[#f4b183] text-xs sm:text-base">{(item/1000).toFixed(2)} To Buy</p>
                            ) : (
                              <p className="text-[#a9d18e] text-xs sm:text-base">
                                {String((item/1000).toFixed(2)).slice(1)} To Sell
                              </p>
                            )}
                          </p>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
              <div className="custom-scroll col-span-12 max-h-[400px] overflow-y-auto rounded-lg shadow-lg lg:col-span-8">
                <div className="sticky top-0">
                  <p className="flex items-center bg-white py-2 px-8 text-lg text-gray-800">
                    Schedule
                  </p>
                  <p className="flex items-center justify-center bg-brand-blue py-2 px-2 text-center text-lg text-white">
                    <span>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="mr-2 h-5 w-5 text-white"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 012.25-2.25h13.5A2.25 2.25 0 0121 7.5v11.25m-18 0A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75m-18 0v-7.5A2.25 2.25 0 015.25 9h13.5A2.25 2.25 0 0121 11.25v7.5"
                        />
                      </svg>
                    </span>
                    Tomorrow{" "}
                  </p>
                </div>
                <div className="px-2 sm:px-8 py-2 text-center sm:text-left">
                  <div className="grid grid-cols-4">
                    <p className="text-sm sm:text-base mb-3 font-bold">Time</p>
                    <p className="text-sm sm:text-base mb-3 font-bold">Buy/Sell (kWh)</p>
                    <p className="text-sm sm:text-base mb-3 font-bold">
                      Energy Tariff Price
                    </p>
                    <p className="text-sm sm:text-base mb-3 font-bold">
                      Energy Usage Forecast (kWh)
                    </p>
                    <div>
                      {time?.map((item, index) => {
                        return (
                          <p
                            className={
                              getHourAndMinute > item
                                ? "text-gray-800 text-xs sm:text-base"
                                : "text-gray-800 text-xs sm:text-base"
                            }
                            key={index}
                          >
                            {" "}
                            {item}
                          </p>
                        );
                      })}
                    </div>
                    <div>
                      {TomorrowPoint?.map((item, index) => {
                        return item >= 0 ? (
                          <p key={index} className="text-[#f4b183] text-xs sm:text-base">
                            {(item/1000).toFixed(2) + " to buy"}
                          </p>
                        ) : (
                          <p key={index} className="text-[#a9d18e] text-xs sm:text-base">
                            {String((item/1000).toFixed(2)).slice(1) + " to sell"}
                          </p>
                        );
                      })}
                    </div>
                    <div>
                      {energyTariffPrice?.map((item, index) => {
                        return <p className="text-xs sm:text-base" key={index}>£ {item}</p>;
                      })}
                    </div>
                    <div>
                      {energyUsageForecast?.map((item, index) => {
                        return <p className="text-xs sm:text-base" key={index}>{(item/1000).toFixed(2)}</p>;
                      })}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </CommonComp>
  );
};
const options3 = {
  series: {
    0: { targetAxisIndex: 0, type: "bars" },
  },
  vAxes: {
    0: { title: "Power", format: "#,###kWh", gridlineColor: "#fff", ticks: 3 },
  },
  legend: { position: "none" },
};
const options1 = {
  series: {
    0: { targetAxisIndex: 0, type: "area", color: "#ffd966"},
    1: { targetAxisIndex: 0, type: "area", color: "#4472c4"},
  },
  vAxes: {
    0: { title: "Power", format: "#,#.##kWh", gridlineColor: "#fff", ticks: 10 },
  },
  legend: { position: "top" },
};
export default Energy;
