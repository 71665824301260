import React, { useState, useEffect } from "react";
import CommonComp from "./CommonComp";
import "react-accessible-accordion/dist/fancy-example.css";
import Collapse, { Panel } from "rc-collapse";
import "rc-collapse/assets/index.css";
const WeatherForecast = ({ authId }) => {
  let auth0Id = localStorage.getItem("userId");
  const apiName = process.env.NODE_ENV === "production" ? process.env.REACT_APP_PRODUCTION_API_URL : process.env.REACT_APP_DEV_API_URL;
  const [forecastUsage, setForecastUsage] = useState();
  const [forecastUsageArr, setForecastUsageArr] = useState([]);
  const [everyUsageObj, setEveryUsageObj] = useState([]);
  const [myNewArr, setMyNewArr] = useState([]);
  const [myDescription, setMyDescription] = useState([]);
  const [myDescriptionIcon, setMyDescriptionIcon] = useState();
  const [solarPower, setSolarPower] = useState();
  const [solarPowerArr, setSolarPowerArr] = useState([]);
  const [peakTimeState, setPeakTime] = useState();
  const [clickOnTab, setClickOnTab] = useState(0);
  const [valueofCli, setValueofCli] = useState(0);
  var d = new Date(); // for now
  d.getHours(); // => 9
  d.getMinutes(); // =>  30
  let getHourAndMinute = d.getHours() + ":" + d.getMinutes();
  const [isDay, setIsDay] = useState("n");
  useEffect(() => {
    // ** this is for main header
    var d = new Date(); // for now
    d.getHours(); 
    d.getMinutes(); 
    let getHourAndMinute = d.getHours() + ":" + d.getMinutes();
    const allUsageEndPoints = [
      {
        endPoint: `${apiName}/weather-forecast.php?forecast_day=today&user_id=${auth0Id}`,
        name: "today",
      },
      {
        endPoint: `${apiName}/weather-forecast.php?forecast_day=todayplus1&user_id=${auth0Id}`,
        name: "todayPlus1",
      },
      {
        endPoint: `${apiName}/weather-forecast.php?forecast_day=todayplus2&user_id=${auth0Id}`,
        name: "todayPlus2",
      },
      {
        endPoint: `${apiName}/weather-forecast.php?forecast_day=todayplus3&user_id=${auth0Id}`,
        name: "todayPlus3",
      },
      {
        endPoint: `${apiName}/weather-forecast.php?forecast_day=todayplus4&user_id=${auth0Id}`,
        name: "todayPlus4",
      },
    ];
    
    const allWeatherApiHandler = async () => {

      const responses = await Promise.all(allUsageEndPoints.map(url => fetch(url.endPoint)));

      const jsonData = await Promise.all(responses.map(response => response.json()));

      const newData = await Promise.all(jsonData.map(item => item.customer_data))

      setEveryUsageObj(newData);
    }
    allWeatherApiHandler();

    async function MainHeaderApi() {
      try {
        const response = await fetch(
          `${apiName}/weather-forecast.php?user_id=${auth0Id}`,
          {
            method: "GET",
            redirect: "follow",
          }
        );
        if (!response.ok) {
          throw new Error("something went wrong");
        }
        const data = await response.json();
        setForecastUsage(data.available_params.forecast_day);
        setForecastUsageArr(Object.keys(data.available_params.forecast_day));
      } catch (error) {
        console.log(error.message);
      }
    }
    MainHeaderApi();
    // ** end here
    async function forecastData() {
      try {
        const response = await fetch(
          `${apiName}/weather-forecast.php?forecast_day=today&user_id=${auth0Id}`,
          {
            method: "GET",
            redirect: "follow",
          }
        );
        if (!response.ok) {
          throw new Error("something went wrong");
        }
        const data = await response.json();
        const firstLight = data.customer_data["First light"];
        const lastLight = data.customer_data["Last light"];
        if (getHourAndMinute > firstLight && getHourAndMinute < lastLight) {
          setIsDay("d");
        }
        setMyDescription(data.customer_data.Description);
        setMyDescriptionIcon(data.customer_data.DescriptionIcon);
        const arr = Object.keys(data.customer_data).filter(function (item) {
          return item !== "Description";
        });
        let elementToMove = "DescriptionIcon";
        let newPosition = 0;
        let indexToRemove = arr.indexOf(elementToMove);
        arr.splice(indexToRemove, 1);
        arr.splice(newPosition, 0, elementToMove);
        setMyNewArr(arr);
      } catch (error) {
        console.log("error.message", error.message);
      }
    }
    forecastData();
    // this area is for solar forecast
    async function solarData() {
      try {
        const response = await fetch(
          `${apiName}/solar-forecast.php?user_id=${auth0Id}`,
          {
            method: "GET",
            redirect: "follow",
          }
        );
        if (!response.ok) {
          throw new Error("something went wrong");
        }
        const data = await response.json();
        const mainData = data.customer_data;
        const peakTime = [
          {
            time: mainData["Highest Power Peak Time - Today"],
            title: "Highest Power Peak Time - Today",
          },
          {
            time: mainData["Highest Power Peak Time - Tomorrow"],
            title: "Highest Power Peak Time - Tomorrow",
          },
        ];
        setPeakTime(peakTime);
        delete mainData["Highest Power Peak Time - Today"];
        delete mainData["Highest Power Peak Time - Tomorrow"];
        setSolarPower(mainData);
        setSolarPowerArr(Object.keys(mainData));
      } catch (error) {
        console.log(error.message);
      }
    }
    solarData();
    // end here
  }, [auth0Id, apiName]);
  useEffect(() => {
    document
      .querySelectorAll(".forecast_border_left")[0]
      ?.setAttribute("style", "border-bottom-left-radius: 0px !important;");
  });
  const setFunctionValue = async (index, item) => {
    setClickOnTab(index);
    try {
      const response = await fetch(
        `${apiName}/weather-forecast.php?forecast_day=${item}&user_id=${auth0Id}`
      );
      if (!response.ok) {
        throw new Error("something went wrong");
      }
      const data = await response.json();

      const firstLight = data.customer_data["First light"];
      const lastLight = data.customer_data["Last light"];
      if (getHourAndMinute > firstLight && getHourAndMinute < lastLight) {
        setIsDay("d");
      }
      setMyDescription(data.customer_data.Description);
      setMyDescriptionIcon(data.customer_data.DescriptionIcon);
    } catch (error) {
      console.log("error.message", error.message);
    }
  };
  // this area is for screen size
  const [screen, setScreen] = useState();
  useEffect(() => {
    const CurrentWidth = window.innerWidth;
    setScreen(CurrentWidth);
    window.addEventListener("resize", function () {
      const CurrentWidth = window.innerWidth;
      setScreen(CurrentWidth);
    });
  }, [auth0Id]);
  return (
    <CommonComp
      title="Weather Forecast"
      bgColor="forecastBg"
      id="forecast"
      textColor="text-brand-blue"
    >
      {screen > 1024 ? (
        <div className="mb-8 grid grid-cols-7">
          <p className="-x-4  col-span-2 flex items-center gap-3 py-4 text-center text-xl font-bold text-brand-blue md:px-8 md:text-left">
            {myDescriptionIcon && (
              <img
                src={require(`../../images/MaximiseMyEnergy_icons/${
                  myDescriptionIcon + isDay
                }.svg`)}
                alt=""
                width="35px"
              />
            )}
            {myDescription}
          </p>
          {forecastUsageArr.map((item, indexs) => {
            return (
              <button
                key={indexs}
                onClick={() => setFunctionValue(indexs, item)}
                className={`col-span-1 mr-[1px] flex items-center bg-brand-blue px-2 py-2 ${
                  clickOnTab === indexs ? "bg-brand-green" : ""
                } my-0 justify-center rounded-t-3xl text-center`}
              >
                <p className="text-center text-sm text-white md:text-lg">
                  {forecastUsage[item]}
                </p>
              </button>
            );
          })}
          {everyUsageObj && (
            <>
              <div className="col-span-2 rounded-l-3xl bg-brand-blue py-4 ">
                {myNewArr.length &&
                  myNewArr?.map((item, index) => {
                    return (
                      <button
                        onClick={() => setValueofCli(index)}
                        key={index}
                        className={`${
                          valueofCli === index ? "bg-brand-green" : ""
                        } ${
                          item === "DescriptionIcon"
                            ? "h-[51px] text-transparent"
                            : "text-white"
                        } w-full px-8 py-2 text-xl`}
                      >
                        {item}
                      </button>
                    );
                  })}
              </div>
              <>
                {everyUsageObj.map((Mainitem, indexss) => {
                  return (
                    <div
                      key={indexss}
                      className={`col-span-1 block pr-[1px]`}
                    >
                      <div
                        className={`forecast_border_left rounded-b-3xl border ${
                          clickOnTab === indexss ? "bg-brand-green" : ""
                        } border-gray-300 pt-4 pb-16`}
                      >
                        {myNewArr?.map((item, index) => {
                          if (item === "Temperature") {
                            return (
                              <div
                                className={`py-2 data${index} ${
                                  valueofCli === index ? "bg-brand-green" : ""
                                }`}
                                key={index}
                              >
                                <p className="text-center text-xl">
                                  {Mainitem[item].toFixed(2)}°C
                                </p>
                              </div>
                            );
                          } else if (item === "Wind Direction") {
                            return (
                              <div
                                className={`py-2 data${index} ${
                                  valueofCli === index ? "bg-brand-green" : ""
                                }`}
                                key={index}
                              >
                                <p className="text-center text-xl">
                                  {Mainitem[item]}°
                                </p>
                              </div>
                            );
                          } else if (
                            item === "Humidty" ||
                            item === "Chance of precipitation"
                          ) {
                            return (
                              <div
                                className={`py-2 data${index} ${
                                  valueofCli === index ? "bg-brand-green" : ""
                                }`}
                                key={index}
                              >
                                <p className="text-center text-xl">
                                  {Mainitem[item]}%
                                </p>
                              </div>
                            );
                          } else if (item === "Windspeed") {
                            return (
                              <div
                                className={`py-2 data${index} ${
                                  valueofCli === index ? "bg-brand-green" : ""
                                }`}
                                key={index}
                              >
                                <p className="text-center text-xl">
                                  {Mainitem[item]}mph
                                </p>
                              </div>
                            );
                          } else {
                            return (
                              <div
                                className={`py-2 data${index} ${
                                  valueofCli === index ? "bg-brand-green" : ""
                                }`}
                                key={index}
                              >
                                <p className="flex justify-center text-center text-xl">
                                  {item === "DescriptionIcon" ? (
                                    <img
                                      src={require(`../../images/MaximiseMyEnergy_icons/${
                                        Mainitem[item] + isDay
                                      }.svg`)}
                                      alt=""
                                      width="35px"
                                    />
                                  ) : (
                                    Mainitem[item]
                                  )}
                                </p>
                              </div>
                            );
                          }
                        })}
                      </div>
                    </div>
                  );
                })}
              </>
            </>
          )}
          {!everyUsageObj && <div className="col-span-7">no data</div>}
        </div>
      ) : (
        <div className="mb-8">
          <p className="-x-4  flex items-center justify-center gap-3 py-4 text-center text-xl font-bold text-brand-blue md:px-8">
            {myDescriptionIcon && (
              <img
                src={require(`../../images/MaximiseMyEnergy_icons/${
                  myDescriptionIcon + isDay
                }.svg`)}
                alt=""
                width="35px"
              />
            )}
            {myDescription}
          </p>
          <Collapse accordion={true} defaultActiveKey={["0"]}>
            {everyUsageObj?.map((item, index) => {
              return (
                <Panel
                  onClick={() =>
                    setFunctionValue(index, forecastUsageArr[index])
                  }
                  key={index}
                  header={
                    forecastUsage && forecastUsageArr
                      ? forecastUsage[forecastUsageArr[index]]
                      : ""
                  }
                  headerClass="my-header-class"
                >
                  {Object.keys(item).map((data, no) => {
                    if (data === "Wind Direction") {
                      return (
                        <div
                          key={no}
                          className="grid grid-cols-8 border-b border-gray-200 py-2 px-3"
                        >
                          <p className="col-span-6 md:col-span-4">{data}</p>
                          <p className="col-span-2 md:col-span-4">
                            {item[data].toFixed(2)}°
                          </p>
                        </div>
                      );
                    } else if (
                      data === "Humidty" ||
                      data === "Chance of precipitation"
                    ) {
                      return (
                        <div
                          key={no}
                          className="grid grid-cols-8 border-b border-gray-200 py-2 px-3"
                        >
                          <p className="col-span-6 md:col-span-4">{data}</p>
                          <p className="col-span-2 md:col-span-4">
                            {item[data]}%
                          </p>
                        </div>
                      );
                    } else if (data === "Windspeed") {
                      return (
                        <div
                          key={no}
                          className="grid grid-cols-8 border-b border-gray-200 py-2 px-3"
                        >
                          <p className="col-span-6 md:col-span-4">{data}</p>
                          <p className="col-span-2 md:col-span-4">
                            {item[data]}mph
                          </p>
                        </div>
                      );
                    } else if (data === "Temperature") {
                      return (
                        <div
                          key={no}
                          className="grid grid-cols-8 border-b border-gray-200 py-2 px-3"
                        >
                          <p className="col-span-6 md:col-span-4">{data}</p>
                          <p className="col-span-2 md:col-span-4">
                            {item[data]}°C
                          </p>
                        </div>
                      );
                    } else if (data === "DescriptionIcon") {
                      return <></>;
                    } else {
                      return (
                        <div
                          key={no}
                          className="grid grid-cols-8 border-b border-gray-200 py-2 px-3"
                        >
                          <p className="col-span-6 md:col-span-4">{data}</p>
                          <p className="col-span-2 md:col-span-4">
                            {item[data]}
                          </p>
                        </div>
                      );
                    }
                  })}
                </Panel>
              );
            })}
          </Collapse>
        </div>
      )}
      <div
        style={{ boxShadow: "0px 0px 19px 0px rgba(0, 0, 0, 0.14)" }}
        className="rounded-2xl bg-white p-4 md:p-8"
      >
        <div className="grid grid-cols-12 gap-6">
          <div className="col-span-12 md:col-span-9 md:px-8">
            <div className="grid w-full grid-cols-12 content-center gap-y-3 md:gap-8">
              {solarPowerArr?.map((item, index) => {
                return (
                  <div
                    key={index}
                    className="col-span-12 flex flex-col items-center justify-center rounded-xl border border-gray-200 p-8 text-center md:col-span-6"
                  >
                    <h2 className="text-sm text-gray-600">{item}</h2>
                    <p className="text-3xl text-gray-600">
                      {solarPower[item]} kWh
                    </p>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="col-span-12 flex h-full flex-col items-center justify-center gap-y-3 md:col-span-3 md:gap-8">
            {peakTimeState?.map((item, index) => {
              if (item.time) {
                return (
                  <div
                    className="w-full rounded-xl bg-gray-100 p-8"
                    key={index}
                  >
                    <h2 className="text-sm text-gray-600">{item.title}</h2>
                    <p className="text-3xl text-gray-600">{item.time}</p>
                  </div>
                );
              } else {
                return <></>;
              }
            })}
          </div>
        </div>
      </div>
    </CommonComp>
  );
};

export const options = {
  chart: {
    title: "Solar Forecast",
    subtitle: "Estimated Energy Production",
  },
  legend: { position: "bottom" },
};
export default WeatherForecast;
