import React from 'react';
import { Navigate } from 'react-router-dom';

const PrivateRoute = ({ component, isAuthenticated, ...rest }) => {
  return (
    <>
        {isAuthenticated ? component : <Navigate to="/authVerify" />}
    </>
  );
};

export default PrivateRoute;