import { Fragment } from "react";
import { Popover, Transition } from "@headlessui/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import { NavHashLink } from "react-router-hash-link";
import { useLocation, useNavigate } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { Link } from "react-router-dom";
export default function Navbar({ setIsAuthenticated }) {
  let { logout } = useAuth0();
  // this is navlinks array 
  const navlinks = [
    { title: "Real Time Usage", link: "#realTimeUsage" },
    { title: "Energy Dashboard", link: "#historical" },
    { title: "Weather and Solar Forecast", link: "#forecast" },
    { title: "Energy Tariffs and Schedules", link: "#energy" },
    { title: "Your Details", link: "#user" },
  ];
  // end here 

  const navigate = useNavigate();

  // this area is for logout function 
  const logoutUser = (e) =>{
    e.preventDefault();
    localStorage.removeItem('userId');
    localStorage.removeItem('userAuth');
    logout();
    setIsAuthenticated(false);
    navigate("/authVerify");
  }
  // end here 
  
  // this area is for internal links 
  const { hash } = useLocation();
  const isactive = (iHash) => hash === iHash;
  return (
    <Popover
      className="bg-white sticky top-0 left-0 w-full z-[999999]"
      style={{ boxShadow: "12.213px 14.555px 46px 0px rgba(0, 0, 0, 0.07)" }}
    >
      <div className="mx-auto container px-6">
        <div className="flex items-center justify-between py-3 lg:space-x-10">
          <div className="flex justify-start">
            <Link to="/">
              <span className="sr-only">Your Company</span>
              <img
                className="h-10 w-auto sm:h-12"
                src={require("../images/logo/logo.png")}
                alt=""
              />
            </Link>
          </div>
          <div className="-my-2 -mr-2 lg:hidden">
            <Popover.Button className="inline-flex items-center justify-center rounded-md bg-white p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none">
              <span className="sr-only">Open menu</span>
              <Bars3Icon className="h-6 w-6" aria-hidden="true" />
            </Popover.Button>
          </div>

          <div className="hidden items-center justify-end lg:flex">
            <Popover.Group as="nav" className="hidden space-x-6 lg:flex">
              {navlinks.map(({ title, link }, index) => {
                return (
                  <NavHashLink
                    to={link}
                    key={index}
                    className="text-sm font-medium text-gray-600 "
                    // activeClassName="active"
                    style={
                      isactive(link)
                        ? {
                            color: "#003c79",
                          }
                        : {}
                    }
                    smooth
                  >
                    {title}
                  </NavHashLink>
                );
              })}
            </Popover.Group>
            <button
              onClick={logoutUser}
              className="ml-8 inline-flex items-center justify-center whitespace-nowrap rounded-full text-sm px-6 py-1 text-white bg-brand-blue"
            >
              Log Out
            </button>
          </div>
        </div>
      </div>
      <Transition
        as={Fragment}
        enter="duration-200 ease-out"
        enterFrom="opacity-0 scale-95"
        enterTo="opacity-100 scale-100"
        leave="duration-100 ease-in"
        leaveFrom="opacity-100 scale-100"
        leaveTo="opacity-0 scale-95"
      >
        <Popover.Panel
          focus
          className="absolute bg-white inset-x-0 top-0 origin-top-right transform p-2 transition lg:hidden"
        >
          <div className="divide-y-2 divide-gray-50 rounded-lg shadow-lg ring-1 ring-black ring-opacity-5">
            <div className="container mx-auto">
              <div className="px-5 pt-5 pb-6">
                <div className="flex items-center justify-between">
                  <div>
                    <img
                      className="h-10 w-auto"
                      src={require("../images/logo/logo.png")}
                      alt="Your Company"
                    />
                  </div>
                  <div className="-mr-2">
                    <Popover.Button className="inline-flex items-center justify-center rounded-md bg-white p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none ">
                      <span className="sr-only">Close menu</span>
                      <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                    </Popover.Button>
                  </div>
                </div>
              </div>
              <div className="space-y-6 py-6 px-5">
                <div className="grid grid-cols-1 sm:grid-cols-2 gap-y-4 gap-x-8">
                  {navlinks.map(({ title, link }, index) => {
                    return (
                      <NavHashLink
                        style={
                          isactive(link)
                            ? {
                                color: "#003c79",
                              }
                            : {}
                        }
                        smooth
                        to={link}
                        key={index}
                        className="text-base font-medium text-gray-600"
                      >
                        {title}
                      </NavHashLink>
                    );
                  })}
                </div>
                <div>
                  <button
                    onClick={logoutUser}
                    className="flex w-full items-center justify-center rounded-full border border-brand-blue px-4 py-2 text-sm font-medium text-white bg-brand-blue shadow-sm"
                  >
                    Log Out
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Popover.Panel>
      </Transition>
    </Popover>
  );
}
