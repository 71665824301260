import React, { useEffect, useReducer, useState } from "react";
import Energy from "./Energy";
import HistoricalUsage from "./HistoricalUsage";
import RealTimeUsage from "./RealTimeUsage";
import User from "./User";
import WeatherForecast from "./WeatherForecast";
import io from "socket.io-client";
const socDom = process.env.NODE_ENV === "production" ? process.env.REACT_APP_PRODUCTION_SOCKET_URL : process.env.REACT_APP_DEV_SOCKET_URL;
export const socket = io(socDom, {
  autoConnect: true,
});
const realDataFunction = (realState, action) => {
  switch (action.type) {
    case 'totalReverseEnergyKwh':
      return { ...realState, totalReverseEnergyKwh: action.value };
    case 'batterySoc':
      return { ...realState, batterySoc: action.value };
    case 'batteryPowerInW':
      return { ...realState, batteryPowerInW: action.value };
    case 'pvKwh':
      return { ...realState, pvKwh: action.value };
    case 'outputPower':
      return { ...realState, outputPower: action.value };
    case 'batteryPowerOutW':
      return { ...realState, batteryPowerOutW: action.value };
    case 'pvW':
      return { ...realState, pvW: action.value };
    case 'batteryTemp':
      return { ...realState, batteryTemp: action.value };
    case 'inputPower':
      return { ...realState, inputPower: action.value };
    case 'inputVoltage':
      return { ...realState, inputVoltage: action.value };
    case 'test':
      return { ...realState, test: action.value };
    default:
      throw new Error();
  }
}
const Home = ({userData, isAuth}) => {
// this area is for kafka  
console.log(process.env.NODE_ENV)
// end here 
  useEffect(() => {
    if(!localStorage.getItem("@@auth0spajs@@::AOoC36PgslKlDqocPCbO12EuFDWo0pCR::default::openid profile email")){
      localStorage.removeItem("userId");
      localStorage.removeItem("userAuth");
    }
  }, [])
  const [userId, setUserId] = useState("")
  useEffect(() => {
    if(userData !== null){
      const newUserId = "auth0_"+userData.sub?.substring(6);
      localStorage.setItem("userId", newUserId);
      setUserId(localStorage.getItem('userId'));
    }
    else{
      setUserId(localStorage.getItem("userId"));
    }
    // console.log(userId)
  }, [userData])
  console.log(userId)
  useEffect(() => {
    if(localStorage.getItem('userId')?.slice(0, 5) === 'auth0|'){
      const newLocalId = "auth0_"+localStorage.getItem('userId').substring(6);
      localStorage.setItem('userId', newLocalId);
    }
  }, [])
  const [realState, dispatchData] = useReducer(realDataFunction, {
    batterySoc: 0,
    outputPower: 0,
    inputPower: 0,
    pvW: 0,
    batteryTemp: 0,
    batteryPowerInW: 0,
    batteryPowerOutW: 0,
  });
  useEffect(() => {
    function onConnect() {
      console.log("socket connected1");
      socket.emit(
        "userRegister",
        userId
      );
      socket.on("kafaka", (arg) => {
        console.log(arg);
        if (arg.type === `mme_${localStorage.getItem('userId')}_1_batterySoc`) {
          dispatchData({ type: 'batterySoc', value: arg.data });
          console.log("batterySoc")
        }
        else if (arg.type === `mme_${localStorage.getItem('userId')}_1_outputPower`) {
          dispatchData({ type: 'outputPower', value: arg.data });
          console.log("outputPower")
        }
        else if (arg.type === `mme_${localStorage.getItem('userId')}_1_inputPower`) {
          dispatchData({ type: 'inputPower', value: arg.data });
          console.log("inputPower")
        }
        else if (arg.type === `mme_${localStorage.getItem('userId')}_1_pvW`) {
          dispatchData({ type: 'pvW', value: arg.data });
          console.log("pvW")
        }
        else if (arg.type === `mme_${localStorage.getItem('userId')}_1_batteryTemp`) {
          dispatchData({ type: 'batteryTemp', value: arg.data });
          console.log("batteryTemp")
        }
        else if (arg.type === `mme_${localStorage.getItem('userId')}_1_batteryPowerInW`) {
          dispatchData({ type: 'batteryPowerInW', value: arg.data });
          console.log("batteryPowerInW")
        }
        else if (arg.type === `mme_${localStorage.getItem('userId')}_1_batteryPowerOutW`) {
          dispatchData({ type: 'batteryPowerOutW', value: arg.data });
          console.log("batteryPowerOutW")
        }
        else {
          dispatchData({ type: 'test', value: arg.data })
          console.log("else")
        }
      });
    }
    socket.on("connect", onConnect);
  }, [userId]);
  if(!isAuth){
    socket.on("disconnect", () => {
      console.log("socket disconnected"); // undefined
    });
  }
  return (
    <>
      <RealTimeUsage
        liveData={realState}
      />
      <HistoricalUsage authId={userId}/>
      <WeatherForecast authId={userId}/>
      <Energy authId={userId}/>
      <User authId={userId}/>
    </>
  );
};

export default Home;
