import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate } from "react-router-dom";
const AuthVerify = ({ setAuthDetails, setIsAuthenticated }) => {
  const navigate = useNavigate();
  const { user, isAuthenticated, isLoading, loginWithRedirect } = useAuth0(); //Auth0 sdk function for finding user is authenticated or not and sending user data
  if (isLoading) {
    return (
      <div className="flex h-screen w-full items-center justify-center bg-black">
        <svg
          version="1.1"
          id="L9"
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          x="0px"
          y="0px"
          className="mx-auto h-52"
          viewBox="0 0 100 100"
          enable-background="new 0 0 0 0"
          xmlSpace="preserve"
          scale="2"
        >
          <path
            fill="#fff"
            d="M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50"
          >
            <animateTransform
              attributeName="transform"
              attributeType="XML"
              type="rotate"
              dur="1s"
              from="0 50 50"
              to="360 50 50"
              repeatCount="indefinite"
            />
          </path>
        </svg>
      </div>
    );
  }
  // this condition run when user is authenticated and send data to App.js file 
  if (isAuthenticated) {
    setAuthDetails(user);
    localStorage.setItem("userAuth", "loginDone");
    setIsAuthenticated(true);
    navigate("/home");
    return <></>;
  }
  // this condition run when user in not authenticated 
  if (isAuthenticated === false && isLoading === false) {
    setIsAuthenticated(false);
    localStorage.removeItem("userAuth");
    localStorage.removeItem("userId");
    const auth0login = () => {
      loginWithRedirect();
    };
    return (
      <section className="login">
        <div className="grid grid-cols-12">
          <div className="loginBg col-span-12 flex min-h-screen flex-col justify-center py-4 md:col-span-6">
            <div className="mx-auto max-w-[600px] px-10">
              <img
                src={require("../images/logo/logo.png")}
                alt=""
                className="mb-14 max-w-[200px]"
              />
              <h2 className="mb-1 text-4xl font-bold text-gray-600">
                Welcome!
              </h2>
              <p className="mb-2 text-xl text-gray-600">
                Please Sign Into Your Account
              </p>
              {/* <p className="text-md mb-10 text-gray-600">
                Please enter your username and password to access your account.
              </p> */}
              {/* <form>
                <div class="mb-6">
                  <label
                    htmlFor="email"
                    className="mb-2 block text-sm font-medium text-gray-700"
                  >
                    Your email
                  </label>
                  <input
                    type="email"
                    id="email"
                    className="block w-full rounded-lg border border-gray-300 bg-white p-2.5 text-sm text-gray-700 focus:border-brand-blue focus:ring-brand-blue"
                    placeholder="name@example.com"
                    required
                  />
                </div>
                <div class="mb-6">
                  <label
                    htmlFor="password"
                    className="mb-2 block text-sm font-medium text-gray-700"
                  >
                    Your Password
                  </label>
                  <input
                    type="password"
                    id="password"
                    className="block w-full rounded-lg border border-gray-300 bg-white p-2.5 text-sm text-gray-700 focus:border-brand-blue focus:ring-brand-blue"
                    placeholder="password"
                    required
                  />
                </div>
              </form> */}
              {/* <div className="my-4 flex items-center">
                <span className="w-4 grow h-0.5 rounded-full bg-gray-400"></span>
                <span className="px-2 text-gray-500">Or</span>
                <span className="w-4 grow h-0.5 rounded-full bg-gray-400"></span>
              </div> */}
              <button
                  onClick={auth0login}
                  type="submit"
                  className={`w-full whitespace-nowrap rounded-lg bg-brand-blue ${
                    isLoading ? "" : "py-3"
                  } text-center text-xl text-white`}
                >
                  {isLoading ? (
                    <svg
                      version="1.1"
                      id="L9"
                      xmlns="http://www.w3.org/2000/svg"
                      xmlnsXlink="http://www.w3.org/1999/xlink"
                      x="0px"
                      y="0px"
                      className="mx-auto h-[52px]"
                      viewBox="0 0 100 100"
                      enable-background="new 0 0 0 0"
                      xmlSpace="preserve"
                      scale="2"
                    >
                      <path
                        fill="#fff"
                        d="M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50"
                      >
                        <animateTransform
                          attributeName="transform"
                          attributeType="XML"
                          type="rotate"
                          dur="1s"
                          from="0 50 50"
                          to="360 50 50"
                          repeatCount="indefinite"
                        />
                      </path>
                    </svg>
                  ) : (
                    "Login"
                  )}
                </button>
            </div>
          </div>
          <div className="relative col-span-12 flex flex-col items-center justify-center bg-[#F5F8FA] px-6 py-4 md:col-span-6 md:min-h-screen">
            <img
              src={require("../images/login/login-bg.png")}
              alt=""
              className="mb-6 w-10/12"
            />
            <h2 className="mb-2 text-center text-2xl text-brand-blue">
              Start Your Journey With Us.
            </h2>
            <p className="text-md text-center text-black text-opacity-60">
              Join us now to enjoy all the benefits of being a member.
            </p>
          </div>
        </div>
      </section>
    );
  } else {
    return <></>;
  }
};

export default AuthVerify;
